import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// import material UI
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid, RowsProp, ColDef } from "@material-ui/data-grid";
import { Paper } from "@material-ui/core";
import { Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import ConnectWallet from "./connectwallet";
import isEmpty from "../../lib/isEmpty";
import Web3 from "web3";
import Web3Modal from "web3modal";
import UPRO_ABI from "./UPRO_ABI.json";
import { providerOptions } from "../../actions/providerOptions";
import { getAllwhitelist, deletewhitelist } from "./../../actions/users";
import { confirmAlert } from "react-confirm-alert"; // Import

// import
import { toastAlert } from "../../lib/toastAlert";
import { useParams } from "react-router-dom";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  flexHeader: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
  },
  btnPrimary: {
    backgroundColor: "#b462c5",
    color: "#ffffff !important",
    padding: "7px 15px",
    fontSize: "12px",
    fontWeight: "700",
  },
};
const useStyles = makeStyles(styles);

export default function EnhancedTable() {
  const classes = useStyles();
  const params = useParams();
  // state
  const [data, setData] = useState([]);
  const [selected, setSelected] = React.useState([]);
  const [walletconnect, setwalletconnect] = useState();
  const web3Modal = new Web3Modal({
    providerOptions, // required,
    cacheProvider: true, // optional
  });
  const columns = [
    { field: "address", headerName: "Address", width: 200 },
    { field: "createdAt", headerName: "created At", width: 200 },

    {
      field: "Action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <Fragment>
            {" "}
            <Link
              onClick={() => handleDelete(params.row._id, params.row.address)}
            >
              <DeleteIcon style={{ color: "#109ebf" }} />
            </Link>
          </Fragment>
        );
      },
    },
  ];

  // function
  const fetchhistory = async () => {
    try {
      let res = await getAllwhitelist();
      if (res && res.result && res.result.data) {
        console.log(res.result, "88484848");

        let respData = res.result.data.map((item, key) => {
          console.log(item, "itemmmmmmmmmmmmmmm");
          return {
            id: key,
            ...item,
          };
        });
        // console.log(respData, 'resssssssssss')
        setData(respData);
      }

      // if (res && res.result) {
      //   console.log("result===================", res);
      //   setUser(res.result.data);
      //   console.log("result of");
      // }
    } catch (err) {
      console.log(err, "errror");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      fetchhistory();
    }, 500);
  }, []);

  const handleDelete = async (iid, address) => {
    try {
      var data = {
        id: iid,
      };

      if (isEmpty(walletconnect)) {
        toastAlert("error", "Please Connnect Metamask", "address");
        return;
      }

      if (web3Modal.cachedProvider) {
        var provider = await web3Modal.connect();
        var web3 = new Web3(provider);
        let contractAddr = "0x5F581cbbE5D96B19A19D3c281D541Fe94cd255FD";

        if (provider && provider?.on) {
          try {
            let result = await web3.eth.getAccounts();
            var metamaskAddress = result[0];
            console.log(metamaskAddress, "metamaskAddress");
            var Contract = new web3.eth.Contract(UPRO_ABI, contractAddr);
            var owner_address = await Contract.methods.OwnerWallet().call();
            console.log(owner_address, "owner_address");

            if (owner_address != metamaskAddress) {
              toastAlert(
                "error",
                "Please Connect Correct Admin wallet",
                "address"
              );
              //   setLoader(false);

              return;
            }
          } catch (err) {
            console.log(err);
            toastAlert("error", "Something went wrong", "address");
            // setLoader(false);

            return;
          }
        }
      }

      confirmAlert({
        title: "Confirm to Delete",
        message: "Do you want to delete this address.",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              let respcont = await Contract.methods
                .removewhitelistedAddress(address)
                .send({ from: metamaskAddress });
              console.log(respcont, "----respcont");

              const result = await deletewhitelist(data);

              toastAlert(
                result?.status ? "success" : "error",
                result.result.data.message,
                "address"
              );

              fetchhistory();
            },
          },

          {
            label: "No",
          },
        ],
      });

      fetchhistory();
    } catch (err) {}
    fetchhistory();
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <div className={classes.root}>
      <ConnectWallet setwalletconnect={setwalletconnect} />

      <Paper className={classes.paper}>
        <Card>
          <CardHeader color="primary">
            <div className={classes.flexHeader}>
              <h4 className={classes.cardTitleWhite}>Whitelist Address</h4>
              <Link to={"/whitelistadd"} className={classes.btnPrimary}>
                Add Whitelist
              </Link>
            </div>
          </CardHeader>
          <CardBody>
            <div style={{ height: 600, width: "100%" }}>
              <DataGrid
                pageSize={10}
                pagination
                rows={data}
                columns={columns}
              />
            </div>
          </CardBody>
        </Card>
      </Paper>
    </div>
  );
}
