import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// import material UI
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid, RowsProp, ColDef } from "@material-ui/data-grid";
import { Paper } from "@material-ui/core";
import { Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

// import action
import {
  getAllpaymentrequest,
  approvepayment,
  rejectpayment,
} from "../../actions/videos";

// import
import { toastAlert } from "../../lib/toastAlert";
import { useParams } from "react-router-dom";
import configSet from "../../lib/config";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  flexHeader: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
  },
  btnPrimary: {
    backgroundColor: "#b462c5",
    color: "#ffffff !important",
    padding: "7px 15px",
    fontSize: "12px",
    fontWeight: "700",
  },
};
const useStyles = makeStyles(styles);

const VideoHistory = (props) => {
  const classes = useStyles();
  const params = useParams();
  // state
  const [data, setData] = useState([]);
  const [disabled, setdisabled] = useState(false);

  const columns = [
    { field: "Address", headerName: "Address", width: 200 },
    // {
    //   field: "id",
    //   headerName: "TxId",
    //   width: 200,
    //   renderCell: (params) => {
    //     return <Fragment>{params?.row?.paymentmethod?.id}</Fragment>;
    //   },
    // },
    { field: "currency", headerName: "Currency", width: 200 },
    { field: "accountType", headerName: "Account Type", width: 200 },
    { field: "bankName", headerName: "Bank", width: 200 },
    { field: "txid", headerName: "Txn Number", width: 200 },
    { field: "Amount", headerName: "Amount", width: 200 },
    { field: "CryptoAmount", headerName: "Upro Amount", width: 200 },
    { field: "walletstatus", headerName: "Status", width: 200 },
    { field: "createdAt", headerName: "Created At", width: 200 },
    {
      field: "Attachment",
      headerName: "Attachment",
      width: 200,
      renderCell: (params) => {
        // console.log(params.row._id,'idddddddddddddd')
        return (
          <Fragment>
            <a
              target="_blank"
              href={configSet.baseUrl + "/payment/" + params.row.attachment}
            >
              View
            </a>
          </Fragment>
        );
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        // console.log(params.row._id,'idddddddddddddd')
        return (
          <Fragment>
            {/* <Link to={"/updateCurrency/" + params.row._id}>
              <EditIcon style={{ color: "#109ebf" }} />
            </Link> */}
            {params.row.walletstatus == "Pending" && (
              <>
                {" "}
                <button
                  style={{ color: "#109ebf" }}
                  onClick={() => approve(params.row._id)}
                  disabled={disabled}
                >
                  {" "}
                  Approve{" "}
                </button>
                {"          "}
                <button
                  style={{ color: "#109ebf" }}
                  onClick={() => reject(params.row._id)}
                  disabled={disabled}
                >
                  {" "}
                  Reject{" "}
                </button>{" "}
              </>
            )}

            {params.row.walletstatus != "Pending" && <span>-</span>}
          </Fragment>
        );
      },
    },
  ];

  // function
  const fetchhistory = async () => {
    try {
      const { status, loading, error, result } = await getAllpaymentrequest();
      if (status == "success") {
        // console.log(result, 'result')
        let respData = result.data.map((item, key) => {
          // console.log(item, 'itemmmmmmmmmmmmmmm')
          return {
            id: key,
            ...item,
          };
        });
        // console.log(respData, 'resssssssssss')
        setData(respData);
      } else {
      }
    } catch (err) {
      console.log(err, "errror");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      fetchhistory();
    }, 500);
  }, []);

  const approve = async (id) => {
    try {
      if (window.confirm("Are you sure you want to approve this payment?")) {
        setdisabled(true);
        const { status, loading, error, message, result } =
          await approvepayment({
            _id: id,
          });
        if (status) {
          await fetchhistory();
          toastAlert("success", message, "currency");
          setdisabled(false);
        } else {
          toastAlert("error", message, "currency");
          setdisabled(false);
        }
      }
    } catch (err) {}
  };

  const reject = async (id) => {
    try {
      if (window.confirm("Are you sure you want to reject this payment?")) {
        setdisabled(true);

        const { status, loading, error, message, result } = await rejectpayment(
          {
            _id: id,
          }
        );
        if (status) {
          await fetchhistory();
          toastAlert("success", "Payment Rejected Successfully", "currency");
          setdisabled(false);
        } else {
          toastAlert("error", error.messages, "currency");
        }
      }
    } catch (err) {}
  };

  // console.log(data, 'datadata')

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Card>
          <CardHeader color="primary">
            <div className={classes.flexHeader}>
              <h4 className={classes.cardTitleWhite}>Payment Request</h4>
            </div>
          </CardHeader>
          <CardBody>
            <div style={{ height: 600, width: "100%" }}>
              <DataGrid
                pageSize={10}
                pagination
                rows={data}
                columns={columns}
              />
            </div>
          </CardBody>
        </Card>
      </Paper>
    </div>
  );
};

export default VideoHistory;
