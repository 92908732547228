// import axios
import axios from "axios";

// import lib
import config from "../lib/config";
import { getAuthToken } from "../lib/localStorage";

axios.defaults.headers.common["Authorization"] = getAuthToken();

export const getAllairdrop = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/airdroplist`,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getAllreferral = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/referrallist`,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getAllkyc = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/kyclist`,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getAllsocial = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/socialhistory`,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const approverejectkyc = async (data) => {
  try {
    // console.log(data, "checking data");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/updatekycstatus`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      result: respData.data.data,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
