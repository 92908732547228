import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PhoneInput from "react-phone-input-2";

import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { toastAlert } from "../../lib/toastAlert";
import browser from "browser-detect";
//import avatar from "assets/img/faces/marc.jpg";
import isEmpty from "../../lib/isEmpty";

import { getcategory, addcategory } from "../../actions/users";

//Wallet connect
import Web3 from "web3";
import Web3Modal from "web3modal";
import { providerOptions } from "../../actions/providerOptions";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const initialFormValue = {
  name: "",
  firstName: "",
  lastName: "",
  email: "",
  phonenumber: "",
  phoneCode: "",
};

const useStyles = makeStyles(styles);

console.log(providerOptions, "providerOptions45");
const web3Modal = new Web3Modal({
  providerOptions, // required,
  cacheProvider: true, // optional
});
export default function Categoryadd(props) {
  const classes = useStyles();
  const history = useHistory();
  const { setwalletconnect } = props;
  const [toched, setToched] = useState({});
  const [Wallet, setWallet] = useState({
    web3: {},
    address: "",
    network: 0,
    provider: {},
    connect: "",
  });

  var isLoad = false;
  const dispatch = useDispatch();

  async function getwalletConnection() {
    try {
      console.log(web3Modal, "11111111111");
      if (web3Modal.cachedProvider) {
        var provider = await web3Modal.connect();

        var web3 = new Web3(provider);
        if (provider && provider?.on) {
          console.log("22222222222");
          const handleAccountsChanged = (accounts: any) => {
            setWallet({
              network: Wallet.network,
              web3: Wallet.web3,
              address: accounts[0],
              provider: Wallet.provider,
              connect: "yes",
            });
            setwalletconnect("yes");
            // window.location.reload(true);
          };

          const handleChainChanged = (chainId: any) => {
            if (chainId !== 56) {
              setWallet({
                network: 0,
                web3: {},
                address: "",
                provider: {},
                connect: "",
              });
            } else {
              setWallet({
                network: chainId,
                web3: Wallet.web3,
                address: Wallet.address,
                provider: Wallet.provider,
                connect: "yes",
              });
              setwalletconnect("yes");
            }
            // window.location.reload(true);
          };

          provider.on("accountsChanged", handleAccountsChanged);
          provider.on("chainChanged", handleChainChanged);
          provider.on("disconnect", disconnectWeb3Wallet);

          return () => {
            if (provider.removeListener) {
              provider.removeListener("accountsChanged", handleAccountsChanged);
              provider.removeListener("chainChanged", handleChainChanged);
              provider.removeListener("disconnect", disconnectWeb3Wallet);
            }
          };
        }
      }
    } catch (err) {}
  }

  async function getConnection() {
    try {
      console.log("connection is ----121212");
      var connect = {
        web3: "",
        address: "",
        network: 0,
        provider: "",
      };

      var provider = "";

      var isConnected = "no";
      var WEB3_CONNECT_CACHED_PROVIDER = localStorage.getItem(
        "WEB3_CONNECT_CACHED_PROVIDER"
      );
      console.log(WEB3_CONNECT_CACHED_PROVIDER, "WEB3_CONNECT_CACHED_PROVIDER");
      if (WEB3_CONNECT_CACHED_PROVIDER) {
        var connnector = JSON.parse(WEB3_CONNECT_CACHED_PROVIDER);
        console.log(connnector, "connnector");

        if (
          connnector === "injected" ||
          connnector === "walletconnect" ||
          connnector === "walletlink" ||
          connnector === "binancechainwallet"
        ) {
          isConnected = "yes";
        }
      }
      console.log(web3Modal.cachedProvider, "ppppppp");

      if (
        !isLoad &&
        provider === "" &&
        isConnected &&
        web3Modal.cachedProvider
      ) {
        isLoad = true;
        provider = await web3Modal.connect();
      }
      if (provider && provider !== "") {
        //var provider = await web3Modal.connect();
        var web3 = new Web3(provider);
        if (typeof web3 !== "undefined") {
          var network = await web3.eth.net.getId();
          var result = await web3.eth.getAccounts();

          var currAddr = result[0];
          console.log(currAddr, "----126");

          setWallet({
            network: network,
            web3: web3,
            address: currAddr,
            provider: provider,
            connect: "yes",
          });
          setwalletconnect("yes");

          if (currAddr === undefined) currAddr = "";
          // if (network === config.NetworkId) {
          //   connect.network = network;
          //   connect.web3 = web3;
          //   connect.address = currAddr;
          //   connect.provider = provider;
          //   connect.connect = "yes";
          //   connect.bnbbal = bnbBalance;
          //   connect.busdBal = busdBal;
          // }
        }
      }
    } catch (err) {
      console.log(err, "-----157");
    }
  }

  const disconnectWeb3Wallet = async () => {
    try {
      console.log("disconnect entry");
      const web3Modal = new Web3Modal({
        providerOptions, // required,
        cacheProvider: false, // optional
      });

      setWallet({
        network: 0,
        web3: {},
        address: "",
        provider: {},
        connect: "",
      });
      // localStorage.clear();

      var cookies = document.cookie.split(";");
      console.log(cookies, "----cookies");

      // for (var i = 0; i < cookies.length; i++) {
      //   var cookie = cookies[i];
      //   var eqPos = cookie.indexOf("=");
      //   var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      //   document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      // }

      await web3Modal.clearCachedProvider();

      setTimeout(function () {
        window.location.reload(false);
      }, 1000);
    } catch (error) {
      console.error(error, "rerereeeeeee");
    }
  };

  async function connectWallet(wallettype) {
    try {
      //97 - Testnet
      //56 - Livenet
      const resultw = browser();
      // alert(resultw.mobile,'reeeeeeeeeeeesdsdsd')
      // alert(resultw.name,'reeeeeeeeeeeesdsdsd')
      // alert(resultw.os,'reeeeeeeeeeeesdsdsd')
      // console.log(resultw,'ressssssssssssssssssssssssssss');

      let check = isMobile();
      if (
        check &&
        !window.ethereum &&
        wallettype != "walletconnect" &&
        resultw.name != "safari"
      ) {
        // let providers = await connectMetamaskMobile();
      }
      console.log(wallettype, "wallettypewallettypewallettype");
      //await web3Modal.clearCachedProvider();
      const web3Modal = new Web3Modal({
        providerOptions, // required,
        cacheProvider: true, // optional
      });

      const provider = await web3Modal.connectTo(wallettype);
      console.log(provider, "-----360");

      var web3 = new Web3(provider);

      // await provider.request({
      //   method: "wallet_switchEthereumChain",
      //   params: [{ chainId: Web3.utils.toHex("97") }],
      // });
      var network = await web3.eth.net.getId();
      console.log(parseInt("473861"), network, ">>>>>>>>>>");
      if (parseInt("473861") === network) {
        var result = await web3.eth.getAccounts();
        var currAddr = result[0];
        console.log(currAddr, "------132");
        setWallet({
          network: network,
          web3: web3,
          address: currAddr,
          provider: provider,
          connect: "yes",
        });

        setwalletconnect("yes");

        // window.$('#connect_wallet_modal').modal('hide');

        toastAlert(
          "success",
          "Your Wallet is Connected Successfully",
          "wallet"
        );

        // setTimeout(function () {
        //   window.location.reload(false);
        // }, 1000);
      } else {
        var errorMsg = "Please select UPRO on your wallet";
        toastAlert("error", errorMsg, "network");
        setwalletconnect("");
        await web3Modal.clearCachedProvider();
      }
    } catch (err) {
      console.log(err, "errerddddddddddrerrerrerr");

      var errorMsg = "Please Connect your Metamask";
      toastAlert("error", errorMsg, "network");
    }
  }

  function isMobile() {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }

  useEffect(() => {
    getConnection();
    getwalletConnection();
  }, []);

  return (
    <div>
      <GridItem xs={12} sm={12} md={3}>
        {Wallet.connect == "yes" && (
          <Button
            onClick={() => {
              disconnectWeb3Wallet();
            }}
            color="danger"
            type="button"
          >
            DisConnect Metamask
          </Button>
        )}
        {Wallet.connect != "yes" && (
          <Button
            color="primary"
            type="button"
            onClick={() => {
              connectWallet("injected");
            }}
          >
            Connect Metamask
          </Button>
        )}
      </GridItem>
    </div>
  );
}
