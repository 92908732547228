import axios from "axios";

// import action
import { decodeJwt } from "./jsonWebToken";

// import lib
import config from "../lib/config";
import setAuthToken from "../lib/setAuthToken";

import { SET_CURRENT_USER } from "../constant";

// import constant
// import {
//     SET_CURRENT_USER
// } from '../constant';

export const getblockcms = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/getblockcms`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const updateblockCms = async (data) => {
  try {
    // console.log(data, "checking data");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/updateblockCms`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getsinglecmss = async (iid) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/getsinglecms/` + iid,
    });
    // console.log(respData.data.data, 'ddddd')
    return {
      status: "success",
      loading: false,
      result: respData,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response,
    };
  }
};

export const updateCms = async (data) => {
  try {
    // console.log(data, "checking data");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/updateCms`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const login = async (data, dispatch) => {
  try {
    // console.log("user ligin");
    // console.log(data, 'dataa')
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/login`,
      data,
    });

    localStorage.setItem("admin_token", respData.data.token);
    setAuthToken(respData.data.token);
    decodeJwt(respData.data.token, dispatch);

    return {
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const acceptverification = async (data) => {
  try {
    // console.log(data, 'dataa')
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/acceptverification`,
      data,
    });

    return {
      loading: false,
      result: respData,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const rejectverification = async (data) => {
  try {
    // console.log(data, 'dataa')
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/rejectverification`,
      data,
    });

    return {
      loading: false,
      result: respData,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const getAllPairs = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/adminapi/getPairs`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      params: data,
    });

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

// Update Currency Id
// export const updateCurrency = async (data,headers) => {
//     console.log(data,"dataaaaaaaaaaaaaaaaaaaa")
//     try {
//         let bodyFormData = new FormData();
//         bodyFormData.append('currencyName', data.currencyName);
//         bodyFormData.append('currencySymbol', data.currencySymbol);
//         bodyFormData.append('currencyType', data.currencyType);
//         bodyFormData.append('photo', data.photo);
//         bodyFormData.append('decimals', data.decimals);
//         bodyFormData.append('fee', data.fee);
//         bodyFormData.append('minimum', data.minimum);
//         bodyFormData.append('contractAddress',data.contractAddress);
//         bodyFormData.append('userId', data.userId);
//         var headersDefault = {
//             'Content-Type': 'multipart/form-data',
//             'Authorization': localStorage.admin_token
//         };
//         var finalHeaders = Object.assign(headersDefault, headers);

//         console.log(bodyFormData,"checking body formdata");
//         console.log(finalHeaders,"finalHeaders");

//         let respData = await axios({
//             'method': 'post',
//             'url': `${config.API_URL}/adminapi/updateCurrency`,
//             headers: finalHeaders,
//             data: bodyFormData
//         });

//     /* Merge defaults and options, without modifying defaults */
//         console.log(respData,'checking ResponseData');
//         return {
//             loading: true,
//             result: respData.data.result
//         }

//     }
//     catch (err) {
//         return {
//             loading: false,
//             error: err
//         }
//     }
// }

export const addFaq = async (data) => {
  try {
    // console.log(data, "checking data");
    // console.log(config.API_URL, 'url')
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/addFaq`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updateFaq = async (data) => {
  try {
    // console.log(data, "checking data");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/updateFaq`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getBidslist = async (filterData, dispatch) => {
  // console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/getBidslist`,
      params: filterData,
    });
    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const gettokenlist = async (filterData, dispatch) => {
  // console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/gettokenlist`,
      params: filterData,
    });
    // console.log(respData, 'response')
    return {
      status: "success",
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
    console.log("not ok");
  }
};

export const getcatory = async (filterData, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/getcatory`,
      params: filterData,
    });
    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const gettokendata = async (id) => {
  // console.log('yes', id)
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/gettokendata/` + id,
    });
    // console.log(respData, 'respDatarespDatarespDatarespData')

    return {
      status: "success",
      loading: false,
      result: respData.data.tokenData,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const deleteToken = async (id, dispatch) => {
  //console.log('yes');
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/deleteToken/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      status: "success",
      loading: false,
      userValue: respData,
      messages: respData.data.message,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const getAllverifylist = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/getAllverifylist`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log('result of Data', respData);
    return {
      loading: false,
      result: respData,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const rejectreq = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/rejectreq`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log('result of Data', respData);
    return {
      loading: false,
      result: respData,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const acceptreq = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/acceptreq`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log('result of Data', respData);
    return {
      loading: false,
      result: respData,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const getAllprohibited = async (data) => {
  try {
    // console.log(data, 'result of Datadfdsfdsfdsfdsfa');

    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/prohibitedlist`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      params: data,
    });
    // console.log('result of Data', respData.data);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const addProhibited = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/addprohibited`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log('result of Data', respData);
    return {
      loading: false,
      result: respData,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const getsingleword = async (data) => {
  try {
    // console.log('confirmmmm', data)
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/singleprohibited`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log('result of Data', respData.data.data);
    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const updateWord = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/updateWord`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log('result of Data', respData.data.data);
    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const deleteWord = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/deleteword`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log(respData.data.message, 'successsss')
    return {
      loading: false,
      result: respData,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

/// get dapp list
export const getdappslist = async (data) => {
  // console.log("get app list");
  try {
    // console.log(data, 'result of Datadfdsfdsfdsfdsfa');

    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/dapplist`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      params: data,
    });
    // console.log('result of Data', respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

// get cat name for dapp
export const getdappcatnameaxios = async (data) => {
  // console.log("get app list");
  try {
    // console.log(data, 'result of Datadfdsfdsfdsfdsfa');

    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/dappcatnamefordropdownbox`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      params: data,
    });
    // console.log('result of Data', respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {}
};
export const getAllcategory = async (data) => {
  try {
    // console.log(data, 'result of Datadfdsfdsfdsfdsfa');

    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/categorylist`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      params: data,
    });
    // console.log('result of Data', respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const getAllCms = async (data) => {
  try {
    // console.log(data, 'cmslistttt');

    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/cmslist`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      params: data,
    });
    // console.log('result of Data', respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const getAllSetting = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/settinglist`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      params: data,
    });
    // console.log('result of Data', respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

// add Dapp cat
export const addDappcat = async (data) => {
  try {
    // console.log("+_+_+_+_+_+_+_+_+_+_+_+_+_+_+");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/addDappcat`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data: data,
    });
    // console.log('result of Data', respData);
    return {
      status: "success",
      loading: false,
      result: respData,
      message: respData.data.message,
    };
  } catch (err) {}
};
export const addcategory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/addcategory`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log('result of Data', respData);
    return {
      status: "success",
      loading: false,
      result: respData,
      message: respData.data.message,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const updateToken = async (reqData) => {
  try {
    // console.log(reqData, 'dataaaaaaaaa')
    let respData = await axios({
      method: "put",
      url: `${config.baseUrl}/adminapi/tokenupdate`,
      data: reqData,
    });
    return {
      status: "success",
      loading: false,
      messages: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const addtoken = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/addtoken`,
      data,
    });
    return {
      status: "success",
      loading: true,
      messages: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updatecategory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/updatecategory`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log('result of', respData);
    return {
      status: "success",
      loading: false,
      result: respData,
      message: respData.data.message,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

// updata Dapp cat
export const updateDAPPcategory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/updateDAPPcategory`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log('ssssss', respData);
    return {
      status: "success",
      loading: false,
      result: respData,
      message: respData.data.messages,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const getDapps = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/Dappdetails`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log('result of Datass', respData);
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const getcategory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/categorydetail`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log('result of Datass', respData);
    return {
      status: "success",
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const getAllUsers = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/userlist`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      params: data,
    });
    // console.log('result of Data', respData);
    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
//Get Currency id currency management module

export const getCurrencyId = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/adminapi/getCurrencyId?userid=` + data,
      headers: {
        Authorization: localStorage.admin_token,
      },
      params: data,
    });
    // console.log('result of Data', respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const getFaqId = async (id) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/getFaq/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log('result of Data', respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const getPairId = async (data) => {
  try {
    // console.log('data', data);
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/adminapi/getPairId?pairid=` + data,
      headers: {
        Authorization: localStorage.admin_token,
      },
      params: data,
    });
    // console.log('result of Data', respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const getUser = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/adminapi/getUsers?userid=` + data,
      headers: {
        Authorization: localStorage.admin_token,
      },
      params: data,
    });
    // console.log('result of Data', respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const updateProfile = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/updateprofile`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log(respData, 'respData')
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    // console.log(err.response, 'errrrrrrrrrrrr')
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const updateUser = async (data) => {
  try {
    let respData = await axios({
      method: "put",
      url: `${config.API_URL}/adminapi/updateUser`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log('result of Data update Profile', respData);
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updateEmail = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/updateEmailTemplate`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log('result of Data update Profile', respData);
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const forgotPassword = async (data) => {
  try {
    // console.log(data, 'aaaaa')
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/forgotPassword`,
      // headers: {
      //     'Authorization': localStorage.admin_token
      // },
      data,
    });

    return {
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const changePassword = async (data) => {
  try {
    // console.log("==================+++++++++==================", data);
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/changePassword`,
      // headers: {
      //     'Authorization': localStorage.admin_token
      // },
      data,
    });
    // console.log(respData, 'resp')
    return {
      status: "success",
      loading: false,
      message: respData.data.messages,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const changeForgorpassword = async (data) => {
  try {
    // console.log("==================+++++++++==================", data);
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/resetPassword`,
      // headers: {
      //     'Authorization': localStorage.admin_token
      // },
      data,
    });
    // console.log(respData, 'resp')
    return {
      status: "success",
      loading: false,
      message: respData.data.messages,
    };
  } catch (err) {
    // console.log(err.response, 'eeeeeeeeeeeeeeee')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.messages,
      error: err.response.data.errors,
    };
  }
};

export const getLoginHistory = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/adminapi/getLoginHistory`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      params: data,
    });
    // console.log('result of Data', respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const getemaillist = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/getemaillist`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      params: data,
    });
    // console.log('result of Data', respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const getFaqList = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/getFaqList`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      params: data,
    });
    // console.log('result of Data', respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const getAddressProof = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/adminapi/proof/getAllAddress`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log('result of Data', respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const getEmailid = async (id) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/getemailid/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log('result of Data', respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const getBankDetails = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/adminapi/proof/getAllBank`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log('result of Data', respData);

    return {
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const UpdateStatus = async (data) => {
  try {
    // console.log('result of Data---', data);
    let respData = await axios({
      method: "put",
      url: `${config.API_URL}/adminapi/proof/updateStatus`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
      // data = {
      //     id:"check",
      //     status:3,
      //     role:1
      //     }
    });
    // console.log('result of Data', respData);

    return {
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const getadminUsers = async (data) => {
  try {
    // console.log(data, 'datatttttttt')
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/adminprofile`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log("aaaww", respData)

    return {
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const get2faCode = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/adminapi/get2faCode`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log('result of Data', respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const update2faCode = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API_URL}/adminapi/update2faCode`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log('result of Data', respData);
    // console.log(data, '------------checkData');

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const disable2faCode = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/adminapi/diabled2faCode`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log('result of Data', respData);
    // console.log(data, '------------checkData');

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const deletecategory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/deletecategory`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log('result of Data', respData);
    return {
      loading: false,
      result: respData,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
/// delete dapp category
export const deletedappcategory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/deletedappcategory`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log('result of Data', respData);
    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const deleteFaq = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/deleteFaq`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log('result of Data', respData);
    return {
      loading: false,
      result: respData,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const getcategorytype = async (token, dispatch) => {
  // console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/getcategorytype`,
    });

    // console.log(respData, "RESdATA")

    return {
      loading: false,
      userValue: respData.data.contactData,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

//Support page
export const getSupportList = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/adminapi/supportList`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log('result of Data', respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

//Using post method only Support page Pass id using delete api

export const deleteSupport = async (id) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API_URL}/adminapi/supportDelete/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log('result of Data', respData);
    // console.log(id, '------------checkData');

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const getSupportId = async (id, dispatch) => {
  // console.log('id', id);

  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/adminapi/getSupportId/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    // console.log(err, 'err');
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const closeticket = async (id, dispatch) => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/adminapi/closeticket/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const savereply = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/adminapi/savereply`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });

    // console.log(respData.data.result,"hsdjfhjksdhfjkdhfjhdsjkghdfjkghjkdfhgjkdfhgjkdfhgjkdhfgjkhdfk");

    return {
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const logout = (history) => {
  localStorage.removeItem("admin_token");
  history.push("/login");
  setAuthToken("");
};

export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const getSetting = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/adminapi/setting`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updateSetting = async (data) => {
  try {
    let respData = await axios({
      method: "put",
      url: `${config.API_URL}/adminapi/setting`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    return {
      status: "success",
      loading: false,
      messages: respData.data.messages,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getvidecatname = async (data) => {
  // console.log("get app list");
  try {
    // console.log(data, 'result of Datadfdsfdsfdsfdsfa');

    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/videocategory`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      params: data,
    });
    // console.log('result of Data', respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {}
};
export const getvidecatnamelang = async (data) => {
  // console.log("get app list");
  try {
    // console.log(data, 'result of Datadfdsfdsfdsfdsfa');

    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/language`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      params: data,
    });
    // console.log('result of Data', respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {}
};

// add Dapp cat
export const addvideo = async (data) => {
  try {
    // console.log("+_+_+_+_+_+_+_+_+_+_+_+_+_+_+");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/addvideo`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data: data,
    });
    // console.log('result of Data', respData);
    return {
      status: respData?.data?.status,
      loading: false,
      result: respData,
      message: respData.data.message,
    };
  } catch (err) {}
};

export const getsinglevideo = async (id, vid) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/getsinglevideo/` + id + "/" + vid,
    });
    // console.log(respData.data.data, 'ddddd')
    return {
      status: "success",
      loading: false,
      result: respData,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response,
    };
  }
};

// add Dapp cat
export const editvideo = async (data) => {
  try {
    // console.log("+_+_+_+_+_+_+_+_+_+_+_+_+_+_+");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/editvideo`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data: data,
    });
    // console.log('result of Data', respData);
    return {
      status: respData.data.status,
      loading: false,
      result: respData,
      message: respData.data.message,
    };
  } catch (err) {}
};

export const addwhitelist = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/whitelistAdd`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log('result of Data', respData);
    return {
      status: respData.data.status,
      loading: false,
      result: respData,
      message: respData.data.message,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const deletewhitelist = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/whitelistDelete`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log('result of Data', respData);
    return {
      loading: false,
      result: respData,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const getAllwhitelist = async (data) => {
  try {
    // console.log(data, 'result of Datadfdsfdsfdsfdsfa');

    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/whitelistlist`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      params: data,
    });
    // console.log('result of Data', respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

// add Dapp cat
export const addslider = async (data) => {
  try {
    // console.log("+_+_+_+_+_+_+_+_+_+_+_+_+_+_+");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/addslider`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data: data,
    });
    // console.log('result of Data', respData);
    return {
      status: "success",
      loading: false,
      result: respData,
      message: respData.data.message,
    };
  } catch (err) {}
};

export const getsingleslider = async (id, vid) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/getsingleslider/` + id,
    });
    // console.log(respData.data.data, 'ddddd')
    return {
      status: "success",
      loading: false,
      result: respData,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response,
    };
  }
};

// add Dapp cat
export const editslider = async (data) => {
  try {
    // console.log("+_+_+_+_+_+_+_+_+_+_+_+_+_+_+");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/editslider`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data: data,
    });
    // console.log('result of Data', respData);
    return {
      status: "success",
      loading: false,
      result: respData,
      message: respData.data.message,
    };
  } catch (err) {}
};

export const deleteslider = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/deleteslider`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log('result of Data', respData);
    return {
      status: "success",
      loading: false,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getAllsliderlist = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/sliderlist`,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updatepricecnv = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/updatepricecnv`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log('result of Data', respData);
    return {
      status: respData.data.status,
      loading: false,
      result: respData,
      message: respData.data.message,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const getbank = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/getbank`,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updateBank = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/updatebank`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log(respData, 'respData')
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    // console.log(err.response, 'errrrrrrrrrrrr')
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getAllInfu = async (data) => {
  try {
    // console.log(data, 'result of Datadfdsfdsfdsfdsfa');

    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/influncerlist`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      params: data,
    });
    // console.log('result of Data', respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const addinfluencer = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/addinfluencer`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log('result of Data', respData);
    return {
      status: "success",
      loading: false,
      result: respData,
      message: respData.data.message,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const addpromotor = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/addPromotors`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log('result of Data', respData);
    return {
      status: "success",
      loading: false,
      result: respData,
      message: respData.data.message,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const getAllpro = async (data) => {
  try {
    console.log(data, "result of Datadfdsfdsfdsfdsfa");

    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/promotorlist/` + data.infId,
      headers: {
        Authorization: localStorage.admin_token,
      },
      params: data,
    });
    // console.log('result of Data', respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data,
      alldata: respData.data.alldata,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const getAlltarget = async (data) => {
  try {
    console.log(data, "result of Datadfdsfdsfdsfdsfa");

    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/promotorstarget/` + data.promId,
      headers: {
        Authorization: localStorage.admin_token,
      },
      params: data,
    });
    console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data,
      failscount: respData.data.failscount,
      alldata: respData.data.alldata,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const getAlltargetinf = async (data) => {
  try {
    console.log(data, "result of Datadfdsfdsfdsfdsfa");

    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/influencerstarget/` + data.infId,
      headers: {
        Authorization: localStorage.admin_token,
      },
      params: data,
    });
    // console.log('result of Data', respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data,
      alldata: respData.alldata,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const sendistri = async (data) => {
  try {
    // console.log(data, "checking data");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/updatedistributebymonth`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      result: respData.data.data,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const sendistriins = async (data) => {
  try {
    // console.log(data, "checking data");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/updatedistributeinfbymonth`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      result: respData.data.data,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const sendistriinfinal = async (data) => {
  try {
    // console.log(data, "checking data");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/updatedistributeinffinal`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      result: respData.data.data,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getAllpromotormembers = async (data) => {
  try {
    console.log(data, "result of Datadfdsfdsfdsfdsfa");

    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/promotorsmembers/` + data.promId,
      headers: {
        Authorization: localStorage.admin_token,
      },
      params: data,
    });
    console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const sendistriprofinal = async (data) => {
  try {
    // console.log(data, "checking data");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/updatedistributeprofinal`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      result: respData.data.data,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
