import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PhoneInput from "react-phone-input-2";
import Select from "@material-ui/core/Select";

import { MenuItem, InputLabel } from "@material-ui/core";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { toastAlert } from "../../lib/toastAlert";
import { getvidecatname } from "./../../actions/users";
import { getvidecatnamelang } from "./../../actions/users";

//import avatar from "assets/img/faces/marc.jpg";

//lib
import fileObjectUrl from "../../lib/fileObjectUrl";

// api
import {
  getcategory,
  addcategory,
  addDappcat,
  addvideo,
} from "../../actions/users";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  textDanger: {
    color: "#f32828",
  },
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const initialFormValue = {
  Title: "",
  Description: "",
  AppImage: "",
  Userurl: "",
};

const useStyles = makeStyles(styles);

export default function Categoryadd(props) {
  const classes = useStyles();
  const history = useHistory();
  const [toched, setToched] = useState({});
  const [caterr, setcaterr] = useState();
  const dispatch = useDispatch();
  const [userdet, setUser] = useState();
  const [selectedcatid, setseletedcatid] = useState();
  const [selectedcatidlang, setseletedcatidlang] = useState();
  const [catname, setcatname] = useState([]);
  const [catnamelang, setcatnamelang] = useState([]);

  const [selectedImage, setSelectedImage] = useState();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});

  const handleFile = (event) => {
    event.preventDefault();
    //alert("sasasasa");
    // console.log(event.target.files[0])
    //settmpupimagefront(URL.createObjectURL(event.target.files[0]));
    const { id, files } = event.target;
    let formData = { ...formValue, ...{ [id]: files[0] } };
    setFormValue(formData);
  };

  const { userId } = useParams();
  const onChange = (e) => {
    setcaterr("");
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);
    // console.log(formValue);
  };

  const { category, Title, Description, AppImage, Userurl } = formValue;

  useEffect(() => {
    getdappcatname();
    getdappcatnamelang();
  }, []);

  const getdappcatname = async (search = {}) => {
    let filterData = search;
    if (filterData.search == "") {
      filterData = {};
    }

    var res = await getvidecatname(filterData);
    setcatname(res.result.data);
    if (res && res.result) {
      setUser(res.result.data);
    }
  };

  const getdappcatnamelang = async (search = {}) => {
    let filterData = search;
    if (filterData.search == "") {
      filterData = {};
    }

    var res = await getvidecatnamelang(filterData);
    setcatnamelang(res.result.data);
    if (res && res.result) {
      setUser(res.result.data);
    }
  };

  // const handlePhoneNumber = (value, country) => {
  //   const { dialCode } = country;
  //   let phoneNo = value;
  //   let formData = formValue;
  //   if (dialCode) {
  //     formData = {
  //       ...formData, ...{
  //         phoneCode: dialCode,
  //         phoneNo: phoneNo.slice(dialCode.length),
  //       }
  //     }
  //   } else if (value) {
  //     formData = { ...formData, ...{ phoneNo } }
  //   }
  //   setFormValue(formData)
  // }
  // const handleBlurPhone = (e) => {
  //   setToched({ ...toched, ...{ 'phoneNo': true, 'phoneCode': true } })
  // }

  const selecedcatis = (e) => {
    setseletedcatid(e.target.value);
  };

  const selecedcatislang = (e) => {
    setseletedcatidlang(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (
      selectedcatid == "" ||
      selectedcatid == null ||
      selectedcatidlang == "" ||
      selectedcatidlang == null ||
      Title == "" ||
      Title == null ||
      Description == "" ||
      Description == null ||
      AppImage == "" ||
      AppImage == null ||
      Userurl == "" ||
      Userurl == null
    ) {
      toastAlert("error", "Enter ALL Field!!!");

      setcaterr("err");
    } else {
      let bodyFormData = new FormData();
      bodyFormData.append("selectedcatid", selectedcatid);
      bodyFormData.append("selectedcatidlang", selectedcatidlang);
      bodyFormData.append("Title", Title);
      bodyFormData.append("Description", Description);
      bodyFormData.append("Userurl", Userurl);
      bodyFormData.append("AppImage", AppImage);
      const { status, loading, message, error } = await addvideo(bodyFormData);
      if (status == "success") {
        toastAlert("success", message, "category");
        history.push("/videolist");
      } else {
        toastAlert("error", message, "category");
      }
    }
  };

  let formdata = {};

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleFormSubmit}
            >
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Video Add</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <div className="custom_select">
                      <InputLabel id="demo-controlled-open-select-label">
                        &nbsp;
                      </InputLabel>
                      <Select
                        className="d-block"
                        name="category"
                        id="category"
                        onChange={selecedcatis}
                      >
                        <MenuItem value="">Select Category</MenuItem>
                        {catname.length > 0 &&
                          catname.map((item, i) => {
                            return (
                              <MenuItem value={item._id}>
                                {item.categoryName}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={3}>
                    <div className="custom_select">
                      <InputLabel id="demo-controlled-open-select-label">
                        &nbsp;
                      </InputLabel>
                      <Select
                        className="d-block"
                        name="language"
                        id="language"
                        onChange={selecedcatislang}
                      >
                        <MenuItem value="">Select Language</MenuItem>
                        {catnamelang.length > 0 &&
                          catnamelang.map((item, i) => {
                            return (
                              <MenuItem value={item.language}>
                                {item.language}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Title"
                      onChange={onChange}
                      id="Title"
                      value={Title}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Description"
                      onChange={onChange}
                      id="Description"
                      value={Description}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="thumbnail image"
                      onChange={handleFile}
                      id="AppImage"
                      type="file"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {AppImage && (
                      <img
                        src={fileObjectUrl(AppImage)}
                        width="50"
                        height="50"
                      />
                    )}

                    {validateError.AppImage && (
                      <span className={classes.textDanger}>
                        {validateError.AppImage}
                      </span>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="URL"
                      onChange={onChange}
                      id="Userurl"
                      value={Userurl}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">
                  Add
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
