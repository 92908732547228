import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PhoneInput from "react-phone-input-2";

import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { toastAlert } from "../../lib/toastAlert";
import ConnectWallet from "./connectwallet";
//import avatar from "assets/img/faces/marc.jpg";
import isEmpty from "../../lib/isEmpty";
import Web3 from "web3";
import Web3Modal from "web3modal";
import { providerOptions } from "../../actions/providerOptions";
import { addwhitelist } from "../../actions/users";
import UPRO_ABI from "./UPRO_ABI.json";
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const initialFormValue = {
  address: "",
};

const useStyles = makeStyles(styles);

export default function Categoryadd(props) {
  const classes = useStyles();
  const history = useHistory();
  const [toched, setToched] = useState({});

  const dispatch = useDispatch();
  const [walletconnect, setwalletconnect] = useState();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});

  const { userId } = useParams();
  const [Loader, setLoader] = useState(false);

  const web3Modal = new Web3Modal({
    providerOptions, // required,
    cacheProvider: true, // optional
  });

  const onChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);
    // console.log(formValue);
  };

  const { address } = formValue;

  useEffect(() => {}, [walletconnect]);

  const handleFormSubmit = async (e) => {
    //console.log("saran");
    e.preventDefault();
    setLoader(true);
    // console.log(formValue);

    if (isEmpty(address)) {
      toastAlert("error", "Please Enter Address", "address");
      setLoader(false);

      return;
    }

    if (isEmpty(walletconnect)) {
      toastAlert("error", "Please Connect Metamask", "address");
      setLoader(false);

      return;
    }

    let addressArr = [];
    addressArr.push(address);
    if (web3Modal.cachedProvider) {
      var provider = await web3Modal.connect();
      var web3 = new Web3(provider);
      let contractAddr = "0x5F581cbbE5D96B19A19D3c281D541Fe94cd255FD";

      if (provider && provider?.on) {
        try {
          let result = await web3.eth.getAccounts();
          var metamaskAddress = result[0];
          console.log(metamaskAddress, "metamaskAddress");
          var Contract = new web3.eth.Contract(UPRO_ABI, contractAddr);
          var owner_address = await Contract.methods.OwnerWallet().call();
          console.log(owner_address, "owner_address");

          if (owner_address != metamaskAddress) {
            toastAlert(
              "error",
              "Please Connect Correct Admin wallet",
              "address"
            );
            setLoader(false);

            return;
          }
        } catch (err) {
          console.log(err);
          toastAlert("error", "Something went wrong", "address");
          setLoader(false);

          return;
        }
      }
    }

    let reqData = {
      address,
    };
    // console.log(reqData);

    try {
      var addwlist = await Contract.methods
        .addwhitelistedAddress(addressArr)
        .send({ from: metamaskAddress });
      console.log(addwlist, "---addwlist");
      let data = await addwhitelist(reqData);

      if (data.status) {
        toastAlert(
          data?.status ? "success" : "error",
          data.result.data.message,
          "address"
        );
        setLoader(false);
      } else {
        toastAlert(
          data?.status ? "success" : "error",
          data.result.data.message,
          "address"
        );
        setLoader(false);
      }

      history.push("/whitelist");
    } catch (err) {
      toastAlert("error", "Error on contract", "address");
    }
  };

  let formdata = {};

  return (
    <div>
      <ConnectWallet setwalletconnect={setwalletconnect} />
      {console.log(walletconnect, "------setwalletconnect")}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleFormSubmit}
            >
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>
                  Add Whitelist Address
                </h4>
                {/* <p className={classes.cardCategoryWhite}>Create a new user</p> */}
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      onChange={onChange}
                      id="address"
                      labelText="Address"
                      value={address}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {toched.address && validateError.address && (
                      <p className="error-message">{validateError.address}</p>
                    )}
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit" disabled={Loader}>
                  Add
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
