import WalletConnect from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";

export const providerOptions = {
  walletlink: {
    package: CoinbaseWalletSDK,
    options: {
      appName: "Quantum",
      rpc: {
        473861: "https://mainnet-rpc.ultraproscan.io/",
        97: "https://data-seed-prebsc-1-s1.binance.org:8545",
      },
    },
  },
  walletconnect: {
    package: WalletConnect,
    options: {
      rpc: {
        473861: "https://mainnet-rpc.ultraproscan.io/",
        97: "https://data-seed-prebsc-1-s1.binance.org:8545	",
      },
    },
    network: "binance",
  },

  binancechainwallet: {
    package: true,
    options: {
      rpc: {
        473861: "https://mainnet-rpc.ultraproscan.io/",
        97: "https://endpoints.omniatech.io/v1/bsc/testnet/public",
      },
    },
  },
};
