import React, { useEffect } from "react";

export default function Referal() {
  useEffect(() => {
    setTimeout(() => {
      window.location.href =
        "https://play.google.com/store/apps/details?id=corvuswallet.io";
    }, 10000);
  }, []);

  return <div></div>;
}
