import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { deleteToken, gettokenlist } from "./../../actions/users";
import { toastAlert } from "../../lib/toastAlert";
// import material UI
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid, RowsProp, ColDef } from "@material-ui/data-grid";
import { Paper } from "@material-ui/core";
import { Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  flexHeader: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
  },
  btnPrimary: {
    backgroundColor: "#b462c5",
    color: "#ffffff !important",
    padding: "7px 15px",
    fontSize: "12px",
    fontWeight: "700",
  },
};
const useStyles = makeStyles(styles);

const TokenList = (props) => {
  // export default function EnhancedTable() {
  const classes = useStyles();
  const [userDetails, setUser] = useState([]);

  const columns = [
    { field: "id", headerName: "ID", hide: true },
    { field: "currencyName", headerName: "Currency Name", width: 200 },
    { field: "currencySymbol", headerName: "Curreny Symbol", width: 200 },
    { field: "type", headerName: "Curreny Type", width: 200 },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        // console.log(params, 'idddddddddddddd')
        return (
          <Fragment>
            <Link onClick={() => editR(params.row._id)}>
              <EditIcon style={{ color: "#109ebf" }} />
            </Link>
            <Link onClick={() => handleDelete(params.row._id)}>
              <DeleteIcon style={{ color: "#109ebf" }} />
            </Link>
          </Fragment>
        );
      },
    },
  ];

  function editR(id) {
    if (id != "") {
      window.location = "/TokenUpdate/" + id;
    }
  }
  function lock() {}

  const getAll = async () => {
    try {
      const { status, loading, error, result } = await gettokenlist();
      if (status == "success") {
        // console.log(result, 'result')
        let respData = result.tokenData.map((item, key) => {
          // console.log(item,'itemmmmmmmmmmmmmmm')
          return {
            id: key,
            ...item,
          };
        });
        // console.log(respData, 'resssssssssss')
        setUser(respData);
      } else {
      }
    } catch (err) {
      console.log(err, "errror");
    }
    // var test = await gettokenlist();
    // console.log("tokenlistt", test.result)
    // let respData = test.result.map((item, key) => {
    //     console.log(item, 'itemmmmmmmmmmmmmmm')
    //     return {
    //         id: key,
    //         ...item
    //     }
    // })
    // setUser(respData);
  };

  const handleDelete = async (currencyId) => {
    try {
      // console.log(currencyId, 'currencyIdcurrencyId')
      const { status, loading, error, messages, result } = await deleteToken(
        currencyId
      );
      if (status == "success") {
        getAll();
        toastAlert("success", messages, "currency");
      } else {
        toastAlert("error", error.messages, "currency");
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  // const handleDelete = async (iid) => {
  //     try {
  //         var data = {
  //             id: iid
  //         }
  //         const res = await deleteToken(iid);
  //         console.log(res, "===ress=============================")
  //         toastAlert('success', res.userValue.data.message, 'currency')
  //         getAll();
  //     } catch (err) { }
  // }

  useEffect(() => {
    setTimeout(() => {
      getAll();
    }, 500);
  }, []);
  // console.log(userDetails, 'user')
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Card>
          <CardHeader color="primary">
            <div className={classes.flexHeader}>
              <h4 className={classes.cardTitleWhite}>Token List</h4>
              <Link to={"/TokenAdd"} className={classes.btnPrimary}>
                Add Token
              </Link>
            </div>
          </CardHeader>
          <CardBody>
            <div style={{ height: 600, width: "100%" }}>
              <DataGrid
                pageSize={10}
                pagination
                rows={userDetails}
                columns={columns}
              />
            </div>
          </CardBody>
        </Card>
      </Paper>
    </div>
  );
};

export default TokenList;
