import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { toastAlert } from "../../lib/toastAlert";

//api
import { addinfluencer } from "../../actions/users";
import { MenuItem, InputLabel } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import isEmpty from "lib/isEmpty";
//styles
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  textDanger: {
    color: "#f32828",
  },
  btnPrimary: {
    backgroundColor: "#b462c5",
    color: "#ffffff !important",
    padding: "7px 15px",
    fontSize: "12px",
    fontWeight: "700",
  },
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const initialFormValue = {
  address: "",
  month: "",
  year: "",
};

const useStyles = makeStyles(styles);

export default function Categoryadd(props) {
  const classes = useStyles();
  const history = useHistory();

  //state
  const [toched, setToched] = useState({});
  const [formValue, setFormValue] = useState(initialFormValue);
  const { month, year, address } = formValue;
  const monthArr = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  //function
  const onChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);
    console.log(formValue);
  };

  // const handlePhoneNumber = (value, country) => {
  //   const { dialCode } = country;
  //   let phoneNo = value;
  //   let formData = formValue;
  //   if (dialCode) {
  //     formData = {
  //       ...formData, ...{
  //         phoneCode: dialCode,
  //         phoneNo: phoneNo.slice(dialCode.length),
  //       }
  //     }
  //   } else if (value) {
  //     formData = { ...formData, ...{ phoneNo } }
  //   }
  //   setFormValue(formData)
  // }
  // const handleBlurPhone = (e) => {
  //   setToched({ ...toched, ...{ 'phoneNo': true, 'phoneCode': true } })
  // }

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
  };

  const handleFormSubmit = async (e) => {
    //console.log("saran");
    e.preventDefault();
    if (isEmpty(address) || (isEmpty(month) && isEmpty(year))) {
      toastAlert("error", "Enter All the fields");
    } else {
      let reqData = {
        address: address,
        month: month,
        year: year,
      };
      console.log(reqData);
      const { status, loading, error, message } = await addinfluencer(reqData);
      console.log(error, "---error");
      if (status == "success") {
        toastAlert("success", message, "influencer");
        history.push("/influencer");
      } else {
        toastAlert("error", error.error, "influencer");
      }
    }
  };

  return (
    <div>
      <div>
        {" "}
        <Link to={"/influencer"} className={classes.btnPrimary}>
          Back
        </Link>
      </div>
      <span>
        {" "}
        Note : Address Should be stake user, Month and Year should be future
      </span>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleFormSubmit}
            >
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>New Influencers</h4>
              </CardHeader>

              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Enter Address"
                      id="address"
                      name="address"
                      value={address}
                      onChange={onChange}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <div className="custom_select">
                      <InputLabel id="demo-controlled-open-select-label">
                        Month
                      </InputLabel>
                      <Select
                        className="d-block"
                        onChange={handleChange}
                        name="month"
                      >
                        {monthArr?.map((item) => {
                          return <MenuItem value={item}>{item}</MenuItem>;
                        })}
                      </Select>
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Year"
                      onChange={onChange}
                      id="year"
                      name="year"
                      value={year}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">
                  Add
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
