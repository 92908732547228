let key = {};
// if (process.env.NODE_ENV === "production") {

if (process.env.REACT_APP_MODE === "production") {
  console.log("Set Production Config");
  const API_URL = "https://producationapi.ultraprowallet.com";
  // const API_URL = 'http://52.206.15.203';
  //const API_URL = 'http://localhost';
  key = {
    // baseUrl: `${API_URL}:2053`,
    baseUrl: `${API_URL}`,
    Front_URL: "https://controls.ultraprowallet.com",
  };
} else if (process.env.REACT_APP_MODE === "development") {
  console.log("Set development Config");
  const API_URL = "https://uproapi.wearedev.team";
  // const API_URL = 'http://52.206.15.203';
  //const API_URL = 'http://localhost';
  key = {
    // baseUrl: `${API_URL}:2053`,
    baseUrl: `${API_URL}`,
    Front_URL: "https://uprowallet-adminpanel.pages.dev",
  };
} else {
  console.log("Set Development Config");
  // const API_URL = 'http://52.206.15.203' ;          //'http://localhost';
  const API_URL = "http://localhost:2053";
  //"https://api.corvuswallet.com";
  key = {
    baseUrl: `${API_URL}`,
    Front_URL: "https://uprowallet-adminpanel.pages.dev",
  };
}

export default key;
