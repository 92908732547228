import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// import material UI
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid, RowsProp, ColDef } from "@material-ui/data-grid";
import { Paper } from "@material-ui/core";
import { Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

// import action
import { getAllsliderlist, deleteslider } from "../../actions/users";

// import
import { toastAlert } from "../../lib/toastAlert";
import { useParams } from "react-router-dom";
import config from "../../lib/config";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  flexHeader: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
  },
  btnPrimary: {
    backgroundColor: "#b462c5",
    color: "#ffffff !important",
    padding: "7px 15px",
    fontSize: "12px",
    fontWeight: "700",
  },
};
const useStyles = makeStyles(styles);

const Sliderlist = (props) => {
  const classes = useStyles();
  const params = useParams();
  // state
  const [data, setData] = useState([]);

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 200,
      renderCell: (params) => {
        return <Fragment>{params.row.title}</Fragment>;
      },
    },

    {
      field: "thumbnail",
      headerName: "Thumbnail",
      width: 200,
      renderCell: (params) => {
        console.log(config, "-------112");
        return (
          <Fragment>
            <img
              width="100"
              height="100"
              src={config.baseUrl + "/slider/" + params.row.thumbnail}
            />
          </Fragment>
        );
      },
    },

    { field: "createdAt", headerName: "Created At", width: 200 },

    {
      field: "Action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <Fragment>
            {" "}
            <Link onClick={() => editR(params.row._id)}>
              <EditIcon style={{ color: "#109ebf" }} />
            </Link>
            <Link onClick={() => handleDelete(params.row && params.row._id)}>
              <DeleteIcon style={{ color: "#109ebf" }} />
            </Link>
          </Fragment>
        );
      },
    },
  ];

  function editR(id, vid) {
    if (id != "") {
      window.location = "/Editslider/" + id;
    }
  }

  const handleDelete = async (mainid) => {
    try {
      // console.log(currencyId, 'currencyIdcurrencyId')
      const { status, loading, error, messages, result } = await deleteslider({
        mainId: mainid,
      });
      if (status == "success") {
        await fetchhistory();
        toastAlert("success", messages, "currency");
      } else {
        toastAlert("error", error.messages, "currency");
      }
    } catch (err) {}
  };

  // function
  const fetchhistory = async () => {
    try {
      const { status, loading, error, result } = await getAllsliderlist();
      if (status == "success") {
        console.log(result, "result---90");
        let respData = result.data.map((item, key) => {
          // console.log(item, 'itemmmmmmmmmmmmmmm')
          return {
            id: key,
            ...item,
          };
        });
        console.log(respData, "resssssssssss");
        setData(respData);
      } else {
      }
    } catch (err) {
      console.log(err, "errror");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      fetchhistory();
    }, 500);
  }, []);

  // console.log(data, 'datadata')

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Card>
          <CardHeader color="primary">
            <div className={classes.flexHeader}>
              <h4 className={classes.cardTitleWhite}>Slider List</h4>
              <Link to={"/addslider"} className={classes.btnPrimary}>
                Add Slider
              </Link>
            </div>
          </CardHeader>
          <CardBody>
            <div style={{ height: 600, width: "100%" }}>
              <DataGrid
                pageSize={10}
                pagination
                rows={data}
                columns={columns}
              />
            </div>
          </CardBody>
        </Card>
      </Paper>
    </div>
  );
};

export default Sliderlist;
